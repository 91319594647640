import React from "react"
import Section from "../../library/section/section"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { faEnvelope} from '@fortawesome/free-regular-svg-icons'
import SectionHeader from "../section-header/section-header"
import contactBannerStyles from "./contact-banner.module.scss"

function ContactBanner(props) {
  const { background } = props

  return (
    <Section bgColor={background || false}>
      <div className={contactBannerStyles.container}>
        <div className={contactBannerStyles.wrapper}>
          <div className={contactBannerStyles.cta}>
            <FontAwesomeIcon
              className={contactBannerStyles.icon}
              icon={faPhoneAlt}
            />
            <p className={contactBannerStyles.label}>Phone</p>
            <p className={contactBannerStyles.text}>(972) 754 9746</p>
          </div>
          <div className={contactBannerStyles.cta}>
            <FontAwesomeIcon
              className={contactBannerStyles.icon}
              icon={faEnvelope}
            />
            <p className={contactBannerStyles.label}>Email</p>
            <p className={contactBannerStyles.text}>Ajoyevents@outlook.com</p>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ContactBanner
