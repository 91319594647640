import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Button from "../../../library/button/button"
import serviceSectionStyles from "./service-section.module.scss"
import ServiceBox from "../../service-box/service-box"

function ServiceSection() {
  const serviceImage = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativeDirectory: { eq: "service" } }) {
        nodes {
          childImageSharp {
            id
            fluid {
              originalImg
            }
          }
        }
      }
    }
  `)

  return (
    <div className={serviceSectionStyles.container}>
      <div className={serviceSectionStyles.wrapper}>
        <ServiceBox
          title="Birthday Party"
          size={2}
          image={
            serviceImage?.images?.nodes[0]?.childImageSharp?.fluid?.originalImg
          }
        />
        <ServiceBox
          title="Baby Shower"
          size={1}
          image={
            serviceImage?.images?.nodes[1]?.childImageSharp?.fluid?.originalImg
          }
        />
      </div>
      <div className={serviceSectionStyles.wrapper}>
        <ServiceBox
          title="Gender Reveal"
          size={1}
          image={
            serviceImage?.images?.nodes[2]?.childImageSharp?.fluid?.originalImg
          }
        />
        <ServiceBox
          title="Holiday Party"
          size={2}
          image={
            serviceImage?.images?.nodes[3]?.childImageSharp?.fluid?.originalImg
          }
        />
      </div>
      <br />
      <Button
        onClick={() => (window.location.href = "/services")}
        label="View Our Services"
      />
    </div>
  )
}

export default ServiceSection
