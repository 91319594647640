import React from "react"
import Button from "../../library/button/button"
import serviceBoxStyle from "./service-box.module.scss"

function ServiceBox(props) {
  const { image, title, size } = props

  const outterStyle = {}
  const style = {
    background: `linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.25)),url(${image})`,
    backgroundSize: "cover",
  }

  return (
    <div
      style={outterStyle}
      className={
        size === 1
          ? serviceBoxStyle.container_small
          : serviceBoxStyle.container_large
      }
    >
      <div style={style} className={serviceBoxStyle.wrapper}>
        <div>
          <h3 className={serviceBoxStyle.title}>{title}</h3>
        </div>
      </div>
    </div>
  )
}

export default ServiceBox
