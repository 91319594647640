import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Section from "../../library/section/section"
import img from "../../images/service/service_2.jpg"
import homePageStyles from "./homepage.module.scss"
import Button from "../../library/button/button"
import ServiceSection from "./service-section/service-section"
import Underline from "../../library/underline/underline"
import Testimonials from "../testimonials/testimonials"
import SectionHeader from "../section-header/section-header"
import ContactBanner from "../contact-banner/contact-banner"

function HomePage() {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "love.jpg" }) {
        id
        childImageSharp {
          sizes {
            src
          }
          fluid {
            ...GatsbyImageSharpFluid
          }
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  return (
    <div>
      <Section>
        <div className={homePageStyles.container}>
          <div className={homePageStyles.about_container}>
            <div className={homePageStyles.image_container}>
              <Img
                className={homePageStyles.image}
                fluid={data.image.childImageSharp.fluid}
                alt="love balloon"
              />
            </div>
            <div className={homePageStyles.content_wrapper}>
              <div>
                <h1 className={homePageStyles.heading}>Joyous Events</h1>
              </div>
              <Underline color={"#333"} />
              <p>
                Bringing dreams and ideas to life, from start to finish and
                everything in between; let us help make your next event fun and
                memorable in the best way! With years of planning experience, we
                carefully craft an event to perfectly fit your needs. Let us
                create your next joyous event!
              </p>
              <br />
              <div className={homePageStyles.about_button}>
                <Button
                  onClick={() => (window.location.href = "/about-us-page")}
                  label="Read More"
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Section
        bgImage={
          "https://images.unsplash.com/photo-1519306980079-d14db7cebf33?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
        }
      >
        <div className={homePageStyles.midpage}>
          <h2 className={homePageStyles.quote}>
            “A goal without a plan is just a wish.”
          </h2>
          <em>-Antoine de Saint-Exupéry</em>
          <Underline color="#fff" />

          <h3 className={homePageStyles.cta}>Let us plan your next event</h3>
          <Button
            isPrimary
            onClick={() => (window.location.href = "/contact-us")}
            label="Contact Us"
          />
        </div>
      </Section>
      <Section>
        <SectionHeader
          header="Services"
          subHeader="A few of the events we specialize in."
        />
        <ServiceSection />
      </Section>
      <Section bgColor="#f3f3f3">
        <Testimonials />
      </Section>
      <ContactBanner />
    </div>
  )
}

export default HomePage
