import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero/hero"
import HomePage from "../components/homepage/homepage"

function IndexPage() {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "home-image.jpg" }) {
        id
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
            originalImg
          }
        }
      }
    }
  `)

const imageData = data?.image?.childImageSharp?.fluid?.originalImg

console.log(data?.image?.childImageSharp)

  return (
    <Layout>
      <SEO title="Home" />
      <Hero image={imageData} homePage></Hero>
      <HomePage />
    </Layout>
  )
}

export default IndexPage
